import { createStore } from 'vuex'

export default createStore({
  state: {
    menu: ['home', 'menus', 'concept', 'restaurants', 'contact', 'careers']
  },
  getters: {
    menu: state => state.menu
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
