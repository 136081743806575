<template>
    <div class="carousel" v-if="mq.mdPlus">
        <Transition name="fade">
            <div v-if="shown" class="carousel__images">
                <Image :source="images[currentImg].leftImage" class="carousel__image"/>
                <Image v-if="images[currentImg].rightImage" :source="images[currentImg].rightImage"
                       class="carousel__image"/>
            </div>
        </Transition>
        <div class="carousel__buttons">
            <button class="button -square carousel__left" @click="changeImage(false)">
                <Icon id="arrow"/>
            </button>
            <button class="button -square" @click="changeImage">
                <Icon id="arrow"/>
            </button>
        </div>
    </div>
    <Swiper v-else class="carousel__mobile" :modules="modules" :free-mode="true" slides-per-view="auto"
            :space-between="30">
        <template v-for="(block, index) in images" :key="index">
            <SwiperSlide class="carousel__mobile-slide" :class="{ '-larger': !block.rightImage }">
                <Image :source="block.leftImage"/>
            </SwiperSlide>
            <SwiperSlide v-if="block.rightImage" class="carousel__mobile-slide">
                <Image :source="block.rightImage"/>
            </SwiperSlide>
        </template>
    </Swiper>
</template>

<script>
import {Icon, Image} from '@/components/global'
import {Swiper, SwiperSlide} from 'swiper/vue'
import {FreeMode} from 'swiper'

export default {
    name: 'ImgCarousel',
    inject: ['mq'],
    components: {
        Icon,
        Image,
        Swiper,
        SwiperSlide
    },
    props: {
        images: Array,
    },
    data() {
        return {
            currentImg: 0,
            shown: true,
            modules: [FreeMode],
        }
    },
    methods: {
        changeImage(navigateRight = true) {
            this.shown = false

            window.setTimeout(() => {
                this.currentImg =
                    navigateRight
                        ? (this.currentImg >= this.images.length - 1) ? 0 : this.currentImg + 1
                        : (this.currentImg <= 0 ? this.images.length : this.currentImg) - 1

                this.shown = true
            }, 200)
        }
    }
}
</script>
