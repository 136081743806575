<template>
    <section data-light-menu="false" data-section="restaurants">
        <Image :source="content.image" class="banner__image" v-reveal/>
    </section>
    <section data-light-menu="false" data-section="restaurants" id="restaurants">
        <div class="restaurants content">
            <h2 class="restaurants__title" v-reveal="{delay: 200}">{{ content.title }}</h2>
            <div class="restaurants__flex">
                <HighlightedTitle :title="content.description" class="restaurants__description text__highlight" v-reveal="{delay: 250}"/>
                <div class="restaurants__restaurants">
                    <RestaurantCard v-for="(restaurant, index) in $beet.restaurants" :content="restaurant"
                                    :key="`restaurant-${index}`" v-reveal="{delay: 200 + (50 * index)}"/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {RestaurantCard} from '@/components/cards'
import {Image} from '@/components/global'
import {HighlightedTitle} from '@/components/small'

export default {
    name: 'RestaurantsSection',
    props: {
        content: {
            type: Object,
            required: true
        }
    },
    components: {
        Image,
        HighlightedTitle,
        RestaurantCard
    }
}
</script>
