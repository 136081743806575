<template>
    <nav class="navbar" :class="{'-light': lightMode}">
        <Transition name="fade">
            <div class="navbar__top" v-if="isAtTop">
                <div class="navbar__top-left">
                    <LangRouterLink :to="{name: 'home'}" class="header__logo" v-reveal>
                        <img src="@/assets/logo.svg" alt=""/>
                    </LangRouterLink>
                    <a :href="$beet.options.navigation.address.link" target="_blank" class="header__top-element" v-reveal="{delay: 200}">
                        <p class="text__accent">[ {{ $beet.options.navigation.address.top }} ]</p>
                        <p class="text">{{ $beet.options.navigation.address.bottom }}</p>
                    </a>
                </div>
                <HighlightedTitle :title="$beet.options.navigation.quote" class="navbar__quote text__highlight" v-reveal="{delay: 250}"/>
            </div>
        </Transition>
        <div class="navbar__bottom" ref="nav">
            <LangRouterLink :to="{name: 'home'}" :class="['navbar__icon', {'light': lightMode}, {'-visible': !isAtTop}]" @click="scrollTopSafety">
                <Icon id="logo" />
            </LangRouterLink>
            <a :href="$beet.options.navigation.booking.link" target="_blank" :class="['button', 'navbar__button', {'-light': lightMode}]" v-reveal="{delay: 300}">
                {{ $beet.options.navigation.booking.label }}
            </a>
            <a v-if="$beet.options.navigation.giftCard.link" :href="$beet.options.navigation.giftCard.link" target="_blank" :class="['button', 'navbar__button -secondary', {'-light': lightMode}]">
              {{ $beet.options.navigation.giftCard.label }}
            </a>
            <ul class="navbar__links" v-reveal="{delay: 350}">
                <li v-for="(menuKey, menuKeyIndex) in $store.getters.menu" :key="menuKeyIndex">
                    <LangRouterLink v-if="!$beet.options.navigation.menu[menuKey]['link']" :to="{name: 'home', hash: `#${menuKey}`}"
                                    :class="['navbar__link', {'-light': lightMode, '-current': currentSection === menuKey}]">
                        {{ $beet.options.navigation.menu[menuKey]['label'] }}
                    </LangRouterLink>
                    <a v-if="$beet.options.navigation.menu[menuKey]['link']" :href="$beet.options.navigation.menu[menuKey]['link']"
                       :class="['navbar__link', {'-light': lightMode, '-current': currentSection === menuKey}]" target="_blank">
                        {{ $beet.options.navigation.menu[menuKey]['label'] }}
                    </a>
                </li>
                <li>
                    <LangSwitch/>
                </li>
            </ul>
        </div>
    </nav>
</template>
<script>
import {Icon, LangRouterLink} from '@/components/global'
import {HighlightedTitle, LangSwitch} from '@/components/small'
import {HasScrollTopButton} from '@/mixins'

export default {
    name: 'DesktopNav',
    mixins: [HasScrollTopButton],
    components: {
        Icon,
        LangRouterLink,
        HighlightedTitle,
        LangSwitch
    },
    data() {
        return {
            isAtTop: true,
            currentSection: null,
            lightMode: false,
            observer: null,
            menuOpened: false,
            prevYPosition: 0,
            scrollDown: true,
            sections: null
        }
    },
    mounted() {
        window.addEventListener('scroll', this.updateIsAtTopState, {passive: true})
    },
    methods: {
        getTargetSection(target) {
            if (!this.scrollDown) return target

            return target.nextElementSibling  ?? this.sections[this.sections.length - 1]
        },
        observerCallback(entries) {
            entries.forEach(entry => {
                this.setScrollDirection()

                if (!this.shouldUpdate(entry)) return

                const target = this.getTargetSection(entry.target)
                this.updateLightMode(target)
                this.updateCurrentSection(target)
            })
        },
        resetData() {
            this.currentSection = null
            this.observer = null
            this.prevYPosition = 0
            this.scrollDown = true
            this.sections = null
        },
        setScrollObserver() {
            this.sections = [...document.querySelectorAll('section[data-light-menu]'), document.querySelector('footer')]
            if (!this.sections.length) window.setTimeout(this.setScrollObserver, 300)
            else {
                this.observer = new IntersectionObserver(this.observerCallback, {
                    rootMargin: `-${(this.$refs.nav.offsetHeight / 4) * 3}px`,
                    threshold: 0
                })
                this.sections.forEach(section => {
                    this.observer.observe(section)
                })
            }
        },
        setScrollDirection() {
            this.scrollDown = window.scrollY > this.prevYPosition
            this.prevYPosition = window.scrollY
        },
        shouldUpdate(entry) {
            return (this.scrollDown && !entry.isIntersecting) || (!this.scrollDown && entry.isIntersecting)
        },
        updateCurrentSection(target) {
            this.currentSection = target.dataset.section
        },
        updateIsAtTopState() {
            this.isAtTop = window.scrollY <= 20
        },
        updateLightMode(target) {
            if (window.scrollY > 0) {
                this.lightMode = target.dataset.lightMenu === 'true'
            }
        }
    },
    watch: {
        '$route.name': {
            handler() {
                this.resetData()
                window.setTimeout(this.setScrollObserver, 400)
            },
            immediate: true
        }
    }
}
</script>