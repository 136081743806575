<template>
    <div class="navbar-mobile__menu">
        <div class="navbar-mobile__panel-buttons">
          <a v-if="$beet.options.navigation.booking.link" :href="$beet.options.navigation.booking.link" target="_blank" class="button navbar-mobile__panel-button">
            {{ $beet.options.navigation.booking.label }}
          </a>
          <a v-if="$beet.options.navigation.giftCard.link" :href="$beet.options.navigation.giftCard.link" target="_blank" class="button navbar-mobile__panel-button -secondary">
            {{ $beet.options.navigation.giftCard.label }}
          </a>
        </div>
        <ul>
            <li v-for="(menuKey, menuKeyIndex) in $store.getters.menu" :key="menuKeyIndex">
                <LangRouterLink v-if="!$beet.options.navigation.menu[menuKey]['link']" :to="{name: 'home', hash: `#${menuKey}`}"
                                class="navbar-mobile__link">
                    {{ $beet.options.navigation.menu[menuKey]['label'] }}
                </LangRouterLink>
                <a v-if="$beet.options.navigation.menu[menuKey]['link']" :href="$beet.options.navigation.menu[menuKey]['link']" class="navbar-mobile__link">
                    {{ $beet.options.navigation.menu[menuKey]['label'] }}
                </a>
            </li>
        </ul>
        <div class="navbar-mobile__link -lang">
            <span v-for="(lang, langKey) in langs" :key="langKey" @click="$i18n.locale = lang"
                  v-show="lang !== $i18n.locale">
                {{ `${$t(`langs.${lang}`)}${lang !== 'it' ? ' / ' : ''}` }}
            </span>
        </div>

        <div class="navbar-mobile__coord -first">
            <p class="text__accent">[ {{ $beet.options.footer.openHours.title }} ]</p>
            <p class="text -break">{{ $beet.options.footer.openHours.description }}</p>
        </div>
        <div class="navbar-mobile__coord">
            <p class="text__accent">[ {{ $beet.options.footer.address.title }} ]</p>
            <RawHtml tag="p" :html="$beet.options.footer.address.description" class="text test__markdown"/>
        </div>

        <Socials class="navbar-mobile__socials"/>
    </div>
</template>

<script>
import {LangRouterLink, RawHtml} from '@/components/global'
import {Socials} from '@/components/small'

export default {
    name: 'MobileNavPanel',
    components: {
        LangRouterLink,
        RawHtml,
        Socials
    },
    data() {
        return {
            langs: ['fr', 'en', 'it']
        }
    }
}
</script>

