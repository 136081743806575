<template>
  <p v-reveal>
    <template v-for="(str, index) in titleSplit">
      <template v-if="index % 2 === 0">{{ str }}</template>
      <em v-else :key="index">{{ str }}</em>
    </template>
    </p>
</template>

<script>
export default {
  name: "HighlightedTitle",
  props: {
    title: { type: String, default: "" },
  },
  computed: {
    titleSplit() {
      return this.title.split("*")
    }
  }
}
</script>
