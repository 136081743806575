<template>
<section class="happiness content" data-light-menu="false" data-section="concept">
        <ImagesSwiper :images="content.images" v-reveal/>
        <div class="happiness__flex">
            <h2 class="happiness__title" v-reveal="{delay: 200}">{{ content.title }}</h2>
            <HighlightedTitle :title="content.description" class="happiness__description text__highlight" v-reveal="{delay: 250}"/>
        </div>
</section>
</template>

<script>
import {HighlightedTitle} from '@/components/small'
import {ImagesSwiper} from '@/components/swipers'
export default {
    name: 'ImagesSwiperSection',
    components: {
        HighlightedTitle,
        ImagesSwiper
    },
    props: {
        content: {
            type: Object,
            required: true
        }
    }
}
</script>