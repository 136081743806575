<template>
    <header>
        <DesktopNav v-if="mq.mdPlus"/>
        <MobileNav v-if="!mq.mdPlus"/>
    </header>
</template>

<script>
import MobileNav from '@/components/navigation/MobileNav.vue'
import DesktopNav from '@/components/navigation/DesktopNav.vue'

export default {
    name: 'SiteNav',
    inject: ['mq'],
    components: {
        DesktopNav, MobileNav,
    }
}
</script>
