<template>
    <section class="atmosphere" data-light-menu="true" data-section="concept">
        <div class="background__atmosphere"></div>
        <div class="atmosphere__top">
            <HighlightedTitle :title="content.description" class="atmosphere__description text__highlight" v-reveal/>
            <h2 class="atmosphere__title" v-reveal="{delay: 200}">{{ content.title }}</h2>
        </div>
        <div class="atmosphere__text">
            <Image :source="content.image" class="atmosphere__image" v-reveal="{delay: 250}"/>
            <div class="atmosphere__bottom" v-reveal="{delay: 300}">
                <p class="atmosphere__symbol">{{ content.and }}</p>
                <p class="atmosphere__word">{{ content.subtitle }}</p>
            </div>
        </div>
        <img src="@/assets/textures/underline.svg" alt="" class="atmosphere__underline"/>
    </section>
</template>

<script>
import {Image} from '@/components/global'
import {HighlightedTitle} from '@/components/small'

export default {
    name: 'LargeImageSection',
    props: {
        content: {
            type: Object,
            required: true
        }
    },
    components: {
        HighlightedTitle,
        Image
    }
}
</script>

