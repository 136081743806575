<template>
    <div class="footer__socials">
        <a v-for="(social, socialKey) in socials" :key="socialKey" :href="social[1]" target="_blank"
           class="footer__social">
            <Icon :id="social[0]"/>
        </a>
    </div>
</template>

<script>
import {Icon} from '@/components/global'

export default {
    name: 'Socials',
    components: {Icon},
    computed: {
        socials() {
            return Object.entries(this.$beet.options.footer.socials)
        }
    }
}
</script>

