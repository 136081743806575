<template>
    <section class="menus content" data-light-menu="false" data-section="menus" id="menus">
        <a v-for="(menu, index) in $beet.menus" :key="`menu-${index}`" :href="menu.menu.original" target="_blank" class="menus__item"
            v-reveal="{delay: 200 + (50 * index)}">
            <div class="menus__item-top">
                <h2 class="menus__title">{{ menu.name }}</h2>
                <div class="menus__text">
                    <p class="text__accent menus__accent">[ {{ menu.note.title }} ]</p>
                    <p class="text menu__description">{{ menu.note.description }}</p>
                </div>
            </div>
            <Image :source="menu.image" class="menus__image"/>
            <button class="button -square menus__button">
                <Icon id="arrow"/>
            </button>
        </a>
    </section>
</template>

<script>
import {Icon, Image} from '@/components/global'
export default {
    name: 'MenusSection',
    components: {
        Icon,
        Image
    }
}
</script>


