<template>
    <div class="navbar__lang">
        <span class="navbar__lang-label">{{ $beet.options.navigation.menu.language }}</span>
        <span class="navbar__lang-selection">
            <button v-for="(lang, langKey) in langs" :key="langKey" v-show="$i18n.locale !== lang"
                  @click="$i18n.locale = lang">
                {{ lang.toUpperCase() }}
            </button>
        </span>
    </div>
</template>

<script>
export default {
    name: 'LangSwitch',
    data() {
        return {
            defaultLang: 'fr',
            langs: ['fr', 'en', 'it']
        }
    }
}
</script>
