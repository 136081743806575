<template>
    <footer data-light-menu="true" data-section="contact" id="contact">
        <div class="footer content">
            <Image :source="$beet.options.footer.texture"  class="footer__background"/>
            <img src="@/assets/textures/underline.svg" alt="" class="footer__underline"/>
            <h2 class="footer__title" v-reveal>
                <span>{{ $beet.options.footer.title.start }}</span>
                <span class="footer__title-accent">{{ $beet.options.footer.title.next }}</span>
            </h2>
            <div class="footer__flex">
                <div class="footer__column">
                    <h2 class="footer__title" v-reveal>{{ $beet.options.footer.title.end }}</h2>
                    <Socials v-if="mq.mdPlus" v-reveal="{delay: 200}"/>
                </div>
                <div class="footer__coord footer__column" ref="footer">
                    <div v-reveal="{delay: 250}">
                        <p class="text__accent">{{ `[ ${$beet.options.footer.openHours.title} ]` }}</p>
                        <p class="text -break">{{ $beet.options.footer.openHours.description }}</p>
                    </div>
                    <div v-reveal="{delay: 300}">
                        <p class="text__accent">{{ `[ ${$beet.options.footer.address.title} ]` }}</p>
                        <RawHtml tag="p" :html="$beet.options.footer.address.description" class="text text__markdown"/>
                    </div>
                    <a :href="$beet.options.footer.route.link" target="_blank" class="button -light" v-reveal="{delay: 300}">{{ $beet.options.footer.route.label }}</a>
                    <Socials v-reveal="{delay: 300}" v-if="!mq.mdPlus"/>
                </div>
                <div class="footer__column" v-reveal="{delay: 350}">
                    <p class="footer__job">{{ $beet.options.footer.jobs.label }}</p>
                    <a :href="$beet.options.footer.jobs.link" target="_blank" class="button -square -light footer__arrow">
                        <Icon id="arrow"/>
                    </a>
                </div>
            </div>
        </div>
        <p class="credits">
            <span v-reveal="{delay: 400}">{{ creditsWithYear }}</span>
            <a v-reveal="{delay: 400}" v-if="$beet.options.footer?.privacy?.link" :href="$beet.options.footer.privacy.link" target="_blank" rel="nofollow">
              {{ $beet.options.footer.privacy.label }}
            </a>
            <span class="credits__line" v-reveal="{delay: 400}">
                <span>{{ $beet.options.footer.credits.author }}</span>
                <a href="https://boitebeet.com/" target="_blank" class="credits__link">{{ ` [ Beet ]` }}</a>
            </span>
        </p>
    </footer>
</template>

<script>
import {Icon, Image, RawHtml} from '@/components/global'
import {Socials} from '@/components/small'

export default {
    name: 'Footer',
    inject: ['mq'],
    components: {
        Icon,
        Image,
        RawHtml,
        Socials
    },
    computed: {
        creditsWithYear() {
            return this.$beet.options.footer.credits.site.replace("[YEAR]", `${new Date().getFullYear()}  ·`)
        }
    }
}
</script>