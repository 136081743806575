<template>
    <nav :class="['navbar-mobile', {'-open': isMenuOpen}]">
        <MobileNavBar @toggle-menu="toggleMenu"/>
        <DropDown :is-open="isMenuOpen">
            <MobileNavPanel />
        </DropDown>
    </nav>
</template>

<script>
import {DropDown} from '@/components/global'
import {MobileNavBar, MobileNavPanel} from '@/components/navigation'

export default {
    name: "MobileNav",
    components: {DropDown, MobileNavBar, MobileNavPanel },
    data() {
        return {
            isMenuOpen: false
        }
    },
    methods: {
        toggleMenu(isOpen) {
            this.isMenuOpen = isOpen
        }
    },
    watch: {
        isMenuOpen() {
            if (this.isMenuOpen && !document.body.classList.contains('--lock')) {
                document.body.classList.add('--lock')
            } else if (!this.isMenuOpen && document.body.classList.contains('--lock')) {
                document.body.classList.remove('--lock')
            }
        },
        '$i18n.locale'() {
            if (this.isMenuOpen) this.isMenuOpen = false
        }
    }
}
</script>


