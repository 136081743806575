<template>
    <section data-light-menu="false" data-section="home">
        <div class="header content">
            <div v-if="mq.mdMinus">
                <HighlightedTitle :title="content.quote" class="header__quote text__highlight"/>
            </div>
            <h1 class="header__title" v-reveal>{{ content.title }}</h1>
        </div>
    </section>
    <section class="banner__container" data-light-menu="false" data-section="home">
        <Image :source="content.image" class="banner__image" v-reveal="{delay: 250}"/>
        <p class="banner__symbol header__symbol" v-reveal="{delay: 200}">
            {{ content.and }}
        </p>
        <p class="banner__word header__word content" v-reveal="{delay: 300}">{{ content.subtitle }}</p>
        <a :href="$beet.options.navigation.address.link" v-if="!mq.mdPlus" class="header__top-element content" v-reveal="{delay: 350}">
            <p class="text__accent">[ {{ $beet.options.navigation.address.top }} ]</p>
            <p class="text">{{ $beet.options.navigation.address.bottom }}</p>
        </a>
    </section>
</template>

<script>
import {Image} from '@/components/global'
import {HighlightedTitle} from '@/components/small'

export default {
    name: 'HeaderSection',
    inject: ['mq'],
    components: {HighlightedTitle, Image},
    props: {
        content: {
            type: Object,
            required: true
        }
    }
}
</script>

