<template>
    <section class="content not-found__header" data-light-menu="false">
        <h1 class="not-found__title" v-reveal>{{ content.title }}</h1>
        <p v-reveal="{delay: 200}">{{ content.description }}</p>
        <LangRouterLink :to="{name: 'home'}" class="button not-found__button" v-reveal="{delay: 250}">
            {{ content.button }}
        </LangRouterLink>
    </section>
</template>

<script>
import {LangRouterLink} from '@/components/global'

export default {
    name: 'HeaderCTASection',
    components: {LangRouterLink},
    props: {
        content: {
            type: Object,
            required: true
        }
    }
}
</script>
