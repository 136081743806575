<template>
    <div class="restaurants__single">
        <a :href="content.website" target="_blank" @mouseenter="hover = true" @mouseleave="hover = false">
            <Image :source="content.image" class="restaurants__image" :class="{'-hover': hover}"/>
        </a>
        <div class="restaurants__bottom">
            <div class="restaurants__bottom-titles">
                <a :href="content.website" target="_blank" class="text__accent">
                    {{ `[ ${content.name} ]` }}
                </a>
                <a :href="content.address.link" target="_blank">
                    {{ content.address.title }}
                </a>
            </div>
            <a :href="content.website" target="_blank"
               :class="['button', '-square', 'restaurants__button', {'-hover': hover}]" @mouseenter="hover = true"
               @mouseleave="hover = false">
                <Icon id="arrow"/>
            </a>
        </div>
    </div>
</template>

<script>
import {Icon, Image} from '@/components/global'

export default {
    name: 'RestaurantCard',
    components: {Image, Icon},
    data() {
        return {
            hover: false
        }
    },
    props: {
        content: {
            type: Object,
            required: true
        }
    }
}
</script>

