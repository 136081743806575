<template>
    <div class="navbar-mobile__top" :class="{ '-open': isOpen }">
        <LangRouterLink :to="{name: 'home'}" @click="scrollTopSafety">
            <img v-if="isOpen" src="@/assets/logoWhite.svg" class="navbar-mobile__logo" alt=""/>
            <img v-if="!isOpen" src="@/assets/logo.svg" class="navbar-mobile__logo" alt=""/>
        </LangRouterLink>
        <div class="navbar-mobile__right">
            <Transition name="fade-fast">
                <a v-if="!isOpen && $beet.options.navigation.booking.link" :href="$beet.options.navigation.booking.link" target="_blank" class="button navbar-mobile__button"
                   :class="{ '-beige': isOpen }">
                  {{ $beet.options.navigation.booking.label }}
                </a>
            </Transition>
            <button @click="toggleMenu">
                <Icon :id="isOpen ? 'close' : 'menu'" class="navbar-mobile__button-menu" :class="{ '-beige': isOpen }"/>
            </button>
        </div>
    </div>
</template>

<script>
import {Icon, LangRouterLink} from '@/components/global'
import {HasScrollTopButton} from '@/mixins'

export default {
    name: 'MobileNavBar',
    mixins: [HasScrollTopButton],
    components: {
        LangRouterLink,
        Icon
    },
    data() {
        return {
            isOpen: false
        }
    },
    emits: ['toggleMenu'],
    methods: {
        toggleMenu() {
            this.isOpen = !this.isOpen
            this.$emit('toggleMenu', this.isOpen)
        }
    },
    watch: {
        '$route'() {
            if(this.isOpen) this.toggleMenu()
        }
    }
}
</script>