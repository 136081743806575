<template>
    <section class="concepts" data-light-menu="true" data-section="concept">
        <div class="background__concept"></div>
        <div class="concepts__single content" :class="`-bg-${content.background}`">
            <div>
                <div class="concepts__top" v-reveal>
                    <h2 class="concepts__title">{{ content.title }}</h2>
                    <p class="concepts__symbol" v-if="!mq.mdPlus">{{ content.and }}</p>
                </div>
                <div class="concepts__image-container">
                    <Image :source="content.image" class="concepts__image" v-reveal="{delay: 200}" />
                    <p class="concepts__symbol" v-reveal v-if="mq.mdPlus">{{ content.and }}</p>
                </div>
                <p class="concepts__word" v-reveal="{delay: 250}">{{ content.subtitle }}</p>
            </div>
            <div class="concepts__text" v-reveal="{delay: 300}">
                <p class="text__accent" v-if="content.accent">[ {{ content.accent }} ]</p>
                <p class="text -break">{{ content.description }}</p>
            </div>
        </div>
    </section>
</template>

<script>
import {Image} from '@/components/global'

export default {
    name: 'RepeatableSection',
    inject: ['mq'],
    props: {
        content: {
            type: Object,
            required: true
        }
    },
    components: {
        Image
    }
}
</script>